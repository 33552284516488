import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Styled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  header {
    width: 100%;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
    }

    ul {
      margin: 0;
      list-style: none;
      display: flex;

      li {
        padding-right: 1.5em;
        font-size: 1.6rem;
        margin: 0;
      }
    }
  }

  @media (max-width: 600px) {
    header {
      flex-direction: column;

      h1 {
        font-size: 1.6rem;
        margin-bottom: 1.5em;
      }

      ul {
        li {
          padding: 0 1em;
          font-size: 1.2rem;
        }
      }
    }
  }
`
const Header = ({ siteTitle, links }) => (
  <Styled>
    <header>
      <h1>
        <Link to="/">{siteTitle}</Link>
      </h1>

      <ul>
        {links.map(link => (
          <li key={link.href}>
            <Link to={link.href}>{link.display}</Link>
          </li>
        ))}
      </ul>
    </header>
  </Styled>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  links: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  links: [],
}

export default Header

// (
//   <Styled>
//     <Header />
//   </Styled>
// )
